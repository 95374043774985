import {
  InfiniteData,
  QueryClient,
  UndefinedInitialDataInfiniteOptions,
  UndefinedInitialDataOptions,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { readContract } from '@wagmi/core';

import { Address } from 'viem';

import { getQueryClient } from '@shared/common/providers/ReactQuery/QueryProviderSsr';
import { wagmiConfig, WagmiConfigChain } from '@shared/common/providers/Web3Provider/wagmi';
import { LAUNCHPAD_VESTING_CONTRACT } from '@shared/constants';
import { getKycStatusFlags } from '@shared/helpers/launchpad';

import {
  DeserializedSelfAllocation,
  DeserializedSelfAllocations,
  DeserializedSelfPool,
  getBanners,
  getCampaignsProjects,
  getKYCAccessToken,
  getKYCBabt,
  getLaunchpadCurrentUser,
  getSelfActiveSalesPools,
  getSelfActiveWhitelistingPools,
  getSelfAllocations,
  getSelfClaimablePools,
  getSelfClosedPools,
  getSelfWhitelistPoolJoiningCriteriaResults,
  LAUNCHPAD_SALE_POOL_TYPE,
  LaunchpadProject,
  LaunchpadSalePoolType,
  POOLS_DEFAULT_PAGE_LIMIT,
  subscribeToLaunchpadNewsletter,
  verifySelfPoolWhitelist,
} from '../../api';
import { AUTH_USER_QUERY_KEY } from './keys';
import { launchpadProjectKey } from './launchpadProjects';

export const launchpadQueryKeys = {
  selfAllocations: [AUTH_USER_QUERY_KEY, 'selfAllocations'],
  getLaunchpadCurrentUser: [AUTH_USER_QUERY_KEY, 'getLaunchpadCurrentUser'],
  getKYCToken: (params: Parameters<typeof getKYCAccessToken>[0]['json']) => [
    AUTH_USER_QUERY_KEY,
    'getKYCToken',
    params,
  ],
  selfActiveWhitelistingPools: [AUTH_USER_QUERY_KEY, 'selfActiveWhitelistingPools'],
  selfActiveWhitelistingPoolsInfinite: [AUTH_USER_QUERY_KEY, 'selfActiveWhitelistingPoolsInfinite'],
  selfActiveSalesPools: [AUTH_USER_QUERY_KEY, 'selfActiveSalesPools'],
  selfActiveSalesPoolsInfinite: [AUTH_USER_QUERY_KEY, 'selfActiveSalesPoolsInfinite'],
  selfClaimablePools: [AUTH_USER_QUERY_KEY, 'selfClaimablePools'],
  selfClaimablePoolsInfinite: (walletAddress: string) => [
    AUTH_USER_QUERY_KEY,
    'selfClaimablePoolsInfinite',
    walletAddress,
  ],
  selfClosedPools: [AUTH_USER_QUERY_KEY, 'selfClosedPools'],
  selfClosedPoolsInfinite: [AUTH_USER_QUERY_KEY, 'selfClosedPoolsInfinite'],
  poolWhitelistJoiningCriteriaResults: (poolId: string) => [
    AUTH_USER_QUERY_KEY,
    'poolWhitelistJoiningCriteriaResults',
    poolId,
  ],
};

export const useGetLaunchpadCurrentUserQuery = (
  options?: Pick<
    UndefinedInitialDataOptions<
      Awaited<ReturnType<typeof getLaunchpadCurrentUser>>,
      unknown,
      unknown,
      any
    >,
    'enabled'
  >,
) => {
  return useQuery({
    queryKey: launchpadQueryKeys.getLaunchpadCurrentUser,
    queryFn: () => getLaunchpadCurrentUser(),
    // TODO: 1. Connect sockets. 2. Remove after subscribe to socket event
    refetchInterval({ state: { data } }) {
      const { isNeedVerification } = getKycStatusFlags(data?.attributes.kycStatus);

      if (isNeedVerification) {
        return 1000 * 60 * 3;
      }

      return false;
    },
    ...options,
  });
};

export const useGetKYCAssessTokenQuery = (
  { sourceId }: Parameters<typeof getKYCAccessToken>[0]['json'],
  options?: Pick<
    UndefinedInitialDataOptions<
      Awaited<ReturnType<typeof getKYCAccessToken>>,
      unknown,
      unknown,
      any
    >,
    'enabled'
  >,
) => {
  return useQuery({
    queryKey: launchpadQueryKeys.getKYCToken({ sourceId }),
    queryFn: () =>
      getKYCAccessToken({
        json: {
          sourceId,
        },
      }),
    ...options,
  });
};

export const useGetKYCBabtLaunchpadMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => getKYCBabt(),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: launchpadQueryKeys.getLaunchpadCurrentUser });
    },
  });
};

export const useSubscribeToLaunchpadNewsletterMutation = () => {
  return useMutation({
    mutationFn: (email: string) => subscribeToLaunchpadNewsletter({ json: { email } }),
  });
};

export const useSelfActiveWhitelistingPoolsInfiniteQuery = ({
  limit = POOLS_DEFAULT_PAGE_LIMIT,
  options,
}: {
  limit?: number;
  options?: Pick<
    UndefinedInitialDataInfiniteOptions<
      Awaited<ReturnType<typeof getSelfActiveWhitelistingPools>>,
      unknown,
      unknown,
      any,
      number
    >,
    'enabled'
  >;
} = {}) => {
  return useInfiniteQuery({
    queryKey: launchpadQueryKeys.selfActiveWhitelistingPoolsInfinite,
    queryFn: ({ pageParam: nextOffset }) =>
      getSelfActiveWhitelistingPools({ searchParams: { limit, offset: nextOffset } }),
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages = []) => {
      return lastPage.length === limit ? limit * pages.length : undefined;
    },
    staleTime: 0,
    ...options,
  });
};

export const useSelfActiveSalesPoolsInfiniteQuery = ({
  limit = POOLS_DEFAULT_PAGE_LIMIT,
  options,
}: {
  limit?: number;
  options?: Pick<
    UndefinedInitialDataInfiniteOptions<
      Awaited<ReturnType<typeof getSelfActiveSalesPools>>,
      unknown,
      unknown,
      any,
      number
    >,
    'enabled'
  >;
} = {}) => {
  return useInfiniteQuery({
    queryKey: launchpadQueryKeys.selfActiveSalesPoolsInfinite,
    queryFn: ({ pageParam: nextOffset }) =>
      getSelfActiveSalesPools({ searchParams: { limit, offset: nextOffset } }),
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages = []) =>
      lastPage.length === limit ? limit * pages.length : undefined,
    staleTime: 0,
    ...options,
  });
};

export const vestingFetchClaimInfo = async ({
  claimContractAddress,
  chainId,
  userWalletAddress,
}: {
  claimContractAddress: Address;
  chainId: WagmiConfigChain['id'];
  userWalletAddress: Address;
}) => {
  const [claimInfo, refundEndDate, refundStartDate] = await Promise.all([
    readContract(wagmiConfig, {
      ...LAUNCHPAD_VESTING_CONTRACT,
      address: claimContractAddress as Address,
      functionName: 'fetchClaimInfo',
      chainId,
      args: [userWalletAddress as Address],
    }),
    readContract(wagmiConfig, {
      ...LAUNCHPAD_VESTING_CONTRACT,
      address: claimContractAddress as Address,
      functionName: 'refundCloseDate',
      chainId,
    }),
    readContract(wagmiConfig, {
      ...LAUNCHPAD_VESTING_CONTRACT,
      address: claimContractAddress as Address,
      functionName: 'refundStartDate',
      chainId,
    }),
  ]);

  return {
    ...claimInfo,
    refundEndDate,
    refundStartDate,
  };
};

export interface DeserializedSelfPoolWithClaimInfo extends DeserializedSelfPool {
  claimInfo?: Awaited<ReturnType<typeof vestingFetchClaimInfo>>;
}

export const setSelfClaimablePoolsInfiniteQueryData = (
  userWalletAddress: Address,
  updated: (
    data?: InfiniteData<DeserializedSelfPoolWithClaimInfo[]>,
  ) => InfiniteData<DeserializedSelfPoolWithClaimInfo[]> | undefined,
) => {
  const queryClient = getQueryClient();

  if (queryClient) {
    queryClient.setQueryData(
      launchpadQueryKeys.selfClaimablePoolsInfinite(userWalletAddress),
      updated,
    );
  }
};

const populatePoolsWithClaimInfo = (
  pools: DeserializedSelfPool[],
  userWalletAddress: Address,
): Promise<DeserializedSelfPoolWithClaimInfo[]> => {
  return Promise.all(
    pools.map(async (pool) => {
      if (!pool.claimContractAddress || !pool.project?.claimingBlockchainId) {
        return pool;
      }

      const claimInfo =
        (await vestingFetchClaimInfo({
          chainId: pool.project.claimingBlockchainId,
          claimContractAddress: pool.claimContractAddress,
          userWalletAddress,
        }).catch((error) => {
          console.error(error);
        })) || undefined;

      return {
        ...pool,
        claimInfo,
      };
    }),
  );
};

export const useSelfClaimablePoolsInfiniteQuery = ({
  limit = POOLS_DEFAULT_PAGE_LIMIT,
  walletAddress,
  options,
}: {
  limit?: number;
  walletAddress: Address;
  options?: Pick<
    UndefinedInitialDataInfiniteOptions<
      Awaited<ReturnType<typeof getSelfClaimablePools>>,
      unknown,
      unknown,
      any,
      number
    >,
    'enabled'
  >;
}) => {
  return useInfiniteQuery({
    queryKey: launchpadQueryKeys.selfClaimablePoolsInfinite(walletAddress),
    queryFn: async ({ pageParam: nextOffset }): Promise<DeserializedSelfPoolWithClaimInfo[]> => {
      const claimablePools = await getSelfClaimablePools({
        searchParams: { limit, offset: nextOffset },
      });

      return populatePoolsWithClaimInfo(claimablePools, walletAddress);
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages = []) =>
      lastPage.length === limit ? limit * pages.length : undefined,
    staleTime: 0,
    ...options,
  });
};

export const useSelfClosedPoolsInfiniteQuery = ({
  limit = POOLS_DEFAULT_PAGE_LIMIT,
  walletAddress,
  options,
}: {
  limit?: number;
  walletAddress: Address;
  options?: Pick<
    UndefinedInitialDataInfiniteOptions<
      Awaited<ReturnType<typeof getSelfClosedPools>>,
      unknown,
      unknown,
      any,
      number
    >,
    'enabled'
  >;
}) => {
  return useInfiniteQuery({
    queryKey: launchpadQueryKeys.selfClosedPoolsInfinite,
    queryFn: async ({ pageParam: nextOffset }) => {
      const pools = await getSelfClosedPools({
        searchParams: { limit: limit, offset: nextOffset },
      });

      return populatePoolsWithClaimInfo(pools, walletAddress);
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages = []) =>
      lastPage.length === limit ? limit * pages.length : undefined,
    staleTime: 0,
    ...options,
  });
};

export const usePoolWhitelistJoiningCriteriaResultsQuery = (
  poolId: string,
  options?: Pick<
    UndefinedInitialDataOptions<
      Awaited<ReturnType<typeof getSelfWhitelistPoolJoiningCriteriaResults>>,
      unknown,
      unknown,
      any
    >,
    'enabled'
  >,
) => {
  return useQuery({
    queryKey: launchpadQueryKeys.poolWhitelistJoiningCriteriaResults(poolId),
    queryFn: () => getSelfWhitelistPoolJoiningCriteriaResults(poolId),
    ...options,
  });
};

export const useVerifySelfPoolWhitelistMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ poolId }: { poolId: string; projectId?: string }) =>
      verifySelfPoolWhitelist(poolId),
    onSuccess: (data, { poolId, projectId }) => {
      queryClient.setQueryData(
        launchpadQueryKeys.poolWhitelistJoiningCriteriaResults(poolId),
        data,
      );

      if (projectId) {
        const projectPoolKey = launchpadProjectKey.project(projectId, false);

        queryClient.setQueriesData(
          {
            predicate: (query) => {
              return (
                query.queryKey[0] === projectPoolKey[0] && query.queryKey[1] === projectPoolKey[1]
              );
            },
          },
          (oldData?: LaunchpadProject) => {
            if (!oldData) {
              return oldData;
            }

            return {
              ...oldData,
              salePool: oldData.salePool.map((pool) => {
                if (pool.id !== poolId) {
                  return pool;
                }

                return {
                  ...pool,
                  attributes: {
                    ...pool.attributes,
                    meta: {
                      ...pool.attributes.meta,
                      isWhitelistParticipant: data.meta.participationVerified,
                    },
                  },
                };
              }),
            };
          },
        );
      }

      const updateDashboardPools = (oldData?: InfiniteData<DeserializedSelfPool[]>) => {
        if (!oldData?.pages.length) {
          return oldData;
        }

        return {
          ...oldData,
          pages: oldData.pages.map((page) => {
            return page.map((pool) => {
              if (pool.id !== poolId) {
                return pool;
              }

              return {
                ...pool,
                meta: {
                  ...pool.meta,
                  whiteListContainsUser: data.meta.participationVerified,
                },
              };
            });
          }),
        };
      };

      queryClient.setQueryData(
        launchpadQueryKeys.selfActiveWhitelistingPoolsInfinite,
        updateDashboardPools,
      );

      queryClient.setQueryData(
        launchpadQueryKeys.selfActiveSalesPoolsInfinite,
        updateDashboardPools,
      );
    },
  });
};

export const useSelfAllocationsQuery = (
  options?: Pick<
    UndefinedInitialDataOptions<
      Awaited<ReturnType<typeof getSelfAllocations>>,
      unknown,
      unknown,
      any
    >,
    'enabled'
  >,
) => {
  return useQuery({
    queryKey: launchpadQueryKeys.selfAllocations,
    queryFn: () => getSelfAllocations(),
    staleTime: 0,
    ...options,
  });
};

export const prefetchSelfAllocationsQuery = (
  clientQuery: QueryClient,
  options?: Parameters<typeof getSelfAllocations>[0],
) => {
  return clientQuery.prefetchQuery({
    queryKey: launchpadQueryKeys.selfAllocations,
    queryFn: () => getSelfAllocations(options),
  });
};

const POOL_TYPE_TO_KEY = {
  [LAUNCHPAD_SALE_POOL_TYPE.FCFS]: 'fcfsAllocation',
  [LAUNCHPAD_SALE_POOL_TYPE.SQRP_GATED]: 'sqrpGatedAllocation',
  [LAUNCHPAD_SALE_POOL_TYPE.PRO_RATA]: 'proRataAllocation',
  [LAUNCHPAD_SALE_POOL_TYPE.PRO_RATA_SQRP_GATED]: 'proRataSqrpGatedAllocation',
} as const;

export const getAllocationsByPoolType = (
  allocations: DeserializedSelfAllocations,
  poolType: LaunchpadSalePoolType,
) => {
  return allocations[POOL_TYPE_TO_KEY[poolType]] || [];
};

export const getPoolAllocation = (
  allocations: DeserializedSelfAllocations | undefined,
  poolType: LaunchpadSalePoolType,
  poolId: string,
): DeserializedSelfAllocation | undefined => {
  const defaultData = { amount: 0, amountBought: 0, sqrpAmount: 0, id: '0', salePool: null };

  if (!allocations) {
    return defaultData;
  }

  const allocationsByPoolType = getAllocationsByPoolType(allocations, poolType);

  const data = allocationsByPoolType.find((allocation) => allocation?.salePool?.id === poolId);

  return data || defaultData;
};

export const useSelfPoolAllocationQuery = (
  { poolType, poolId }: { poolType: LaunchpadSalePoolType; poolId: string },
  options?: Pick<
    UndefinedInitialDataOptions<
      Awaited<ReturnType<typeof getPoolAllocation>>,
      unknown,
      unknown,
      any
    >,
    'enabled'
  >,
) => {
  const { data: userAllocations } = useSelfAllocationsQuery();

  const keys = [
    ...(userAllocations?.fcfsAllocation?.map((el) => el.id) || []),
    ...(userAllocations?.sqrpGatedAllocation?.map((el) => el.id) || []),
    ...(userAllocations?.proRataAllocation?.map((el) => el.id) || []),
    ...(userAllocations?.proRataSqrpGatedAllocation?.map((el) => el.id) || []),
  ];

  return useQuery({
    queryKey: [...launchpadQueryKeys.selfAllocations, poolType, poolId, ...keys],
    queryFn: () => getPoolAllocation(userAllocations, poolType, poolId),
    staleTime: 0,
    ...options,
  });
};

export const useUpdateSelfAllocationQuery = ({
  poolType,
  poolId,
}: {
  poolType: LaunchpadSalePoolType;
  poolId: string;
}) => {
  const queryClient = useQueryClient();
  const { data: userAllocations } = useSelfAllocationsQuery();

  const keys = [
    ...(userAllocations?.fcfsAllocation?.map((el) => el.id) || []),
    ...(userAllocations?.sqrpGatedAllocation?.map((el) => el.id) || []),
  ];

  return (sqrpUpdated: number) => {
    queryClient.setQueryData(
      [...launchpadQueryKeys.selfAllocations, poolType, poolId, ...keys],
      (oldData: DeserializedSelfAllocation | undefined) => {
        const result = oldData
          ? {
              ...oldData,
              sqrpAmount: (oldData?.sqrpAmount || 0) + sqrpUpdated || 0,
            }
          : oldData;
        return result;
      },
    );
  };
};

export const useGetBannersQuery = () => {
  return useQuery({
    queryKey: ['mainPageBanners'],
    queryFn: () => getBanners(),
  });
};

export const useGetCampaignsProjectsQuery = () => {
  return useQuery({
    queryKey: ['campaignsProjects'],
    queryFn: () => getCampaignsProjects(),
  });
};
