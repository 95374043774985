import { Address } from 'viem';

import { DeserializedStorePageApp, WalletType } from '@api';

import { DeserializedData } from '@shared/helpers/jsonapi';

import { HotOffer } from './hotOffers';
import { MembershipTierId } from './membership';
import { ReviewFeedbackData } from './review';

export enum ExtraFeatures {
  magicWall = 'magic_wall',
}

export interface MembershipModel {
  tier: MembershipTierId;
}

export interface MembershipTierModel {
  id: number;
  type: 'TIER';
  attributes: {
    description: string;
    key: MembershipTierId;
    lockAmount: number;
    deactivationLock?: string;
    name: string;
  };
}

export interface DeserializedUser {
  id: number;
  type: 'USER';
  socials: SocialInterface[];
  wallets: WalletInterface[];
  membershipTier: MembershipTierModel;
  scheduledTierChanges: ScheduledTierChange[] | null;
  attributes: {
    connected: number;
    createdAt: string;
    creatorId: number;
    faceCheckPassed: boolean;
    description: string | null;
    developerId: string;
    developerNameId: string;
    displayedName: string;
    downloads: number;
    email: string;
    emailCodeInvalidAttempts: number;
    emailConfirmed: number;
    extraFeatures?: ExtraFeatures[] | null;
    hasFreePlus: boolean;
    icon: string;
    karma: number;
    name: string;
    referralId: string;
    referredBy: number | null;
    strategy: 'MSQ' | 'USER' | 'GIFTCARD' | 'OFF';
    suspicious: boolean;
    disabled: boolean;
    gitcoinAcceptable: boolean;
    gitcoinScore?: number;
    gitcoinThreshold: number;
    updatedAt: string;
    uuid: string;
    cover: string | null;
    wallet: string;
    adSettings?: {
      enabled: boolean;
      counters: {
        impressions: number;
      };
    };
  };
  meta?: {
    jti?: string;
  };
}

export type ScheduledTierChange = DeserializedData<
  'SCHEDULED_TIER_CHANGE',
  {
    isDue: boolean;
    targetTier: MembershipTierId;
    timestamp: string;
  }
>;

export type DeserializedPublicUser = Pick<DeserializedUser, 'id'> & {
  type: 'PUBLIC_USER';
  attributes: Pick<
    DeserializedUser['attributes'],
    'icon' | 'name' | 'uuid' | 'description' | 'karma'
  > & {
    magicId: string;
    appsCount?: number;
    membership?: MembershipModel;
  };
};

export type KarmaPublicUser = DeserializedPublicUser['attributes'] &
  Pick<DeserializedPublicUser, 'id'>;

export const WALLET_NETWORK = {
  EVM: 'EVM',
  SOLANA: 'SOLANA',
  TON: 'TON',
} as const;

export type WalletNetwork = (typeof WALLET_NETWORK)[keyof typeof WALLET_NETWORK];

export interface WalletInterface {
  id: number;
  type: 'WALLET';
  attributes: {
    address: Address;
    isMain: 1 | 0;
    wallet: WalletType;
    network: WalletNetwork;
  };
}

export interface SocialInterface {
  id: number;
  type: 'TWITTER' | 'DISCORD' | 'TELEGRAM';
  attributes: {
    isVerified: number;
    link: string;
    visibleOnMagicId: boolean;
  };
}

export type ClaimRewardsResponse =
  | {
      account: Address;
      amountInWei: bigint;
      transactionId: string;
      timestampLimit: number;
      signature: Address;
    }
  | {
      status: 'error';
    }
  | {
      error: string;
    };

interface BaseJsonApiData {
  id: number;
  type: string;
}

interface Attributes {
  magicId: string;
  displayName: string;
  description: string;
  avatar: string;
  karmaPoints: number;
  cover: string | null;
}

export interface DeserializedReviewUser {
  type: 'trust-review' | 'suggestion' | 'review';
  id: number;
  attributes: {
    app_id: number;
    user_name: string;
    title: string;
    text: string;
    create_time?: string;
    timestamp?: string;
    trustScore?: number | string | null;
    rating?: number;
  } & ReviewFeedbackData;
  app: DeserializedStorePageApp;
}

export interface DeserializedUserPublicProfile
  extends BaseJsonApiData,
    Pick<DeserializedUser, 'membershipTier'> {
  attributes: Attributes;
  appsVoted: DeserializedStorePageApp[];
  appsFollowed: DeserializedStorePageApp[];
  reviews: DeserializedReviewUser[];
  claimedHotOffers: HotOffer[];
}

export interface FaceLivenessResult {
  id: string;
  type: string;
  attributes: {
    status: boolean;
    livenessCheckPassed: boolean;
    uniquenessCheckPassed: boolean;
  };
}

export interface FaceLivenessSession {
  type: 'FL_SESSION';
  id: string;
}

export type MissingField =
  | 'EVM'
  | 'SOLANA'
  | 'name'
  | 'email'
  | 'avatar'
  | 'displayedName'
  | 'humanityScore';

export interface MissingFieldsEntryDescription {
  avatar?: null;
  displayedName?: null;
  email?: null;
  name?: null;
  wallets?: {
    EVM?: null;
    SOLANA?: null;
  };
  humanityScore?: {
    acceptable: boolean;
    nextUpdateDate?: string;
    threshold?: number;
    score?: number;
  };
  faceCheckPassed?: false;
}

export interface MissingFieldsEntry {
  success: boolean;
  description: MissingFieldsEntryDescription;
}

export interface ConnectedTonWallet {
  id: number;
  wallet: string;
  network: 'TON';
  address: Address;
  isMain: 1 | 0;
  userId: number;
  updatedAt: string | null;
  createdAt: string;
}
